import { PathGpsCoordinateResponse } from '../../interfaces/backend/path.interface';

export function getLineHeight(min: any, max: any, tot: any) {
  if (max === 0 || min === 0 || tot === 0) {
    return 0;
  }
  return (max - min) * ((100 / (tot + 100)) * 14);
}

export function getTextHeight(textHeight: number) {
  return textHeight + ' ' + 'm.ö.h';
}

export function replaceWithBr(text: string) {
  return text?.replace(/\n/g, '<br />');
}

export function formatLength(length: number) {
  return `${length.toFixed(1).replace('.', ',')} km`;
}

export function difficultyMappings(difficulty: string, useEnglish?: boolean) {
  switch (difficulty) {

    case 'veryEasy':
      return useEnglish ? 'Very easy' : 'Mycket lätt';
    case 'easy':
      return useEnglish ? 'Easy' : 'Lätt';
    case 'medium':
      return useEnglish ? 'Medium' : 'Medel';
    case 'hard':
      return useEnglish ? 'Hard' : 'Svår';
    default:
      return difficulty;
  }
}
export function getMaxHeight(pathGPSCoords: PathGpsCoordinateResponse[]) {
  let max = 0;
  for (let i = 0; i < pathGPSCoords?.length; i++) {
    if (pathGPSCoords[i].elevation > max) {
      max = pathGPSCoords[i].elevation;
    }
  }
  return Math.round(max);
}

export function getMinHeight(pathGPSCoords: PathGpsCoordinateResponse[]) {
  let min = 7000;
  for (let i = 0; i < pathGPSCoords?.length; i++) {
    if (pathGPSCoords[i].elevation < min) {
      min = pathGPSCoords[i].elevation;
    }
  }
  return Math.round(min);
}
export function reverseIndex(
  i: number,
  reversed: boolean,
  pathGpsCoordinates: PathGpsCoordinateResponse[]
) {
  if (reversed) {
    return pathGpsCoordinates.length - 1 - i;
  }
  return i;
}

export function getDistanceToStart(
  i: number,
  pathGPSCoords: PathGpsCoordinateResponse[],
  reversed: boolean
) {
  if (i === 0 && reversed) {
    return getTotalDistance(pathGPSCoords);
  }
  if (i === 0) {
    return 0.0;
  }
  if (reversed) {
    i = reverseIndex(i, reversed, pathGPSCoords);
  }

  const p = 0.017453292519943295;
  const c = Math.cos;
  let distanceToStart = 0;

  for (i; i > 1; i--) {
    const lat1 = pathGPSCoords[i].latitude;
    const lat2 = pathGPSCoords[i - 1].latitude;
    const lon1 = pathGPSCoords[i].longitude;
    const lon2 = pathGPSCoords[i - 1].longitude;

    const a =
      0.5 -
      c((lat2 - lat1) * p) / 2 +
      (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;

    distanceToStart += 12742 * Math.asin(Math.sqrt(a));
  }
  return distanceToStart;
}

export function getTotalElevation(pathGPSCoords: PathGpsCoordinateResponse[]) {
  let max = 0;
  let min = 7000;
  for (let i = 0; i < pathGPSCoords?.length; i++) {
    if (pathGPSCoords[i].elevation < min) {
      min = pathGPSCoords[i].elevation;
    }
    if (pathGPSCoords[i].elevation > max) {
      max = pathGPSCoords[i].elevation;
    }
  }
  const maxElevation = Math.round(max * 10) / 10;
  const minElevation = Math.round(min * 10) / 10;
  return Math.round(((maxElevation - minElevation) * 10) / 10);
}

function calculateElevationGain(
  elevationPoints: number[],
  options = {
    smoothingWindowSize: 4,
    decimationInterval: 5,
    elevationThreshold: 1,
  }
) {
  const {
    smoothingWindowSize, // Smoothing window size
    decimationInterval, // Decimation interval (keep every Nth point)
    elevationThreshold, // Ignore elevation changes smaller than this
  } = options;

  // Step 1: Decimate the data
  const decimatedPoints = [];
  for (let i = 0; i < elevationPoints.length; i += decimationInterval) {
    decimatedPoints.push(elevationPoints[i]);
  }

  // Step 2: Smooth the data
  const smoothedPoints = smoothElevationData(
    decimatedPoints,
    smoothingWindowSize
  );

  // Step 3: Calculate elevation gain
  let elevationGain = 0;
  for (let i = 1; i < smoothedPoints.length; i++) {
    const gain = smoothedPoints[i] - smoothedPoints[i - 1];
    if (gain > elevationThreshold) {
      elevationGain += gain;
    }
  }

  return elevationGain;
}

// Smoothing function: Moving average
function smoothElevationData(elevationPoints: number[], windowSize: number) {
  const smoothedElevations = [];
  for (let i = 0; i < elevationPoints.length; i++) {
    const start = Math.max(0, i - Math.floor(windowSize / 2));
    const end = Math.min(elevationPoints.length, i + Math.ceil(windowSize / 2));
    const window = elevationPoints.slice(start, end);
    const average = window.reduce((sum, val) => sum + val, 0) / window.length;
    smoothedElevations.push(average);
  }
  return smoothedElevations;
}

export function getElevationGain(pathGPSCoords: PathGpsCoordinateResponse[]) {
  const elevationPoints = pathGPSCoords.map((coord) => coord.elevation);
  const elevationGain = calculateElevationGain(elevationPoints, {
    smoothingWindowSize: 4,
    decimationInterval: 5,
    elevationThreshold: 1,
  });
  return elevationGain;
}

export function getTotalDistance(pathGPSCoords: PathGpsCoordinateResponse[]) {
  let totalDistance = 0;
  const p = 0.017453292519943295;
  const c = Math.cos;
  for (let i = 1; i < pathGPSCoords?.length; i++) {
    const lat1 = pathGPSCoords[i - 1].latitude;
    const lat2 = pathGPSCoords[i].latitude;
    const lon1 = pathGPSCoords[i - 1].longitude;
    const lon2 = pathGPSCoords[i].longitude;

    const a =
      0.5 -
      c((lat2 - lat1) * p) / 2 +
      (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;

    totalDistance += 12742 * Math.asin(Math.sqrt(a));
  }
  return totalDistance;
}
